<template>
  <div class="terms-of-service">
    <div class="hero is-medium">
      <div class="hero-body">
        <div class="columns is-multiline">
          <div class="column is-8 is-offset-2">
            <simsed-logo width="48" font-size="1.5em" />
            <h1 class="has-text-centered">
              School information management application
            </h1>
          </div>
          <div class="column is-8 is-offset-2">
            <h2 class="title is-4">Privacy Policy</h2>
            <p class="subtitle is-6">
              Simsed, is accessible from https://simsed.com, having a secured
              connection layer.
            </p>
            <p class="subtitle is-6">
              One of our main priorities is the privacy of our visitors. This
              Privacy Policy document contains types of information that is
              collected and recorded by Simsed and how we use it.
            </p>

            <p class="subtitle is-6">
              If you have additional questions or require more information about
              our Privacy Policy, do not hesitate to contact us. You can reach
              us via info@simsed.com
            </p>
            <h3 class="title is-5 mt-2">Log Files</h3>
            <p class="subtitle is-6 has-text-justified">
              Simsed follows a standard procedure of using log files. These
              files log visitors when they visit websites. All hosting companies
              do this and a part of hosting services' analytics. The information
              collected by log files include internet protocol (IP) addresses,
              browser type, Internet Service Provider (ISP), date and time
              stamp, referring/exit pages, and possibly the number of clicks.
              These are not linked to any information that is personally
              identifiable. The purpose of the information is for analyzing
              trends, administering the site, tracking users' movement on the
              website, and gathering demographic information.
            </p>
            <h3 class="title is-5 mt-2">Cookies and Web Beacons</h3>
            <p class="subtitle is-6 has-text-justified">
              Like any other website, Simsed uses 'cookies'. These cookies are
              used to store information including visitors' preferences, and the
              pages on the website that the visitor accessed or visited. The
              information is used to optimize the users' experience by
              customizing our web page content based on visitors' browser type
              and/or other information.
            </p>
            <h3 class="title is-5 mt-2">Advertisement</h3>
            <p class="subtitle is-6 has-text-justified">
              Simsed is not collecting any information for advertisement neither
              does it interface with Third-party ad servers or ad networks that
              uses such technologies like cookies, JavaScript, or Web Beacons
              for advertisements.
            </p>
            <h3 class="title is-5 mt-2">School's Information</h3>
            <p class="subtitle is-6 has-text-justified">
              The School information we collect are used for proper
              indentification of the school and for operational purposes. Such
              information includes all school user's data which are stored for
              central access for the authorized school. Simsed protects your
              school's users data from been accesible by unauthorized entities.
              Another part of our priority is adding this protection and
              ensuring that all school's data remain accessible only to those
              authorized to use such data by the school. We encourage all school
              users to observe, participate in ensuring that passwords are kept
              confidential.
            </p>
            <p class="subtitle is-6 has-text-justified">
              We collect the following information:
            </p>

            <ol class="subtitle is-6 has-text-justified pl-4">
              <li>School name and location</li>
              <li>School educators demographic</li>
              <li>School students demographic</li>
              <li>School parents demographic</li>
              <li>School students subject scores</li>
              <li>School psychomotors of students</li>
              <li>School comments of students</li>
            </ol>

            <h3 class="title is-5 mt-2">Online Privacy Policy Only</h3>
            <p class="subtitle is-6 has-text-justified">
              This Privacy Policy applies only to our online activities and is
              valid for visitors to our website with regards to the information
              that they shared and/or collect in Simsed. This policy is not
              applicable to any information collected offline or via channels
              other than this website.
            </p>
            <h3 class="title is-5 mt-2">Consent</h3>
            <p class="subtitle is-6 has-text-justified">
              By using our website, you hereby consent to our Privacy Policy and
              agree to its Terms and Conditions.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SimsedLogo from '../components/SimsedLogo'

export default {
  data() {
    return {}
  },
  components: {
    SimsedLogo,
  },
}
</script>
